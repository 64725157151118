import { Box, Button, Input, Tab, Tabs, Typography } from "@mui/material"
import AppHeader from "../layout/desktop/AppHeader"
import Footer from "../layout/Footer"
import { CloudUpload, Map } from "@mui/icons-material"
import { Link, useNavigate } from "react-router-dom"
import TutorialSlideshow from "../components/Landing/TutorialSlideshow"
import { useEffect } from "react"
import posthog from "posthog-js"
import { readProjectFromFile } from "../components/Project/project"

const DesktopLanding: React.FC = () => {
    // get a navigate 
    const navigate = useNavigate()

    useEffect(() => {
        posthog.capture('LandingPageView')
    }, [])
    return <>
        <Box display="flex" flexDirection="column" width="100vw" height="100vh">
            <AppHeader>
                <Tabs>
                    <Tab component={Link} to="/app" label="Karte" value="map" icon={<Map />} iconPosition="start" />
                </Tabs>
            </AppHeader>

            <Box 
                width="100vw" 
                height="calc(100vh - 104px)" 
                m="0" 
                p="0" 
                sx={{
                    background: 'url(/icons/background.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}

            >
                <Box 
                    m={3} 
                    p={3} 
                    borderRadius="25px" 
                    sx={{backgroundColor: 'rgba(255, 255, 255, 0.5)', height: 'calc(100% - 48px)', boxSizing: 'border-box'}}
                    display="flex"
                    flexDirection="column"
                    //alignItems="center"
                >
                    <Typography variant="h2" textAlign="center">Willkommen beim Agroforst-Planungstool!</Typography>
                    <Typography variant="h4" textAlign="center">- Demo-Version -</Typography>
                    <Typography variant="body1" textAlign="center" mx={5}>
                        Plane dein eigenes Agroforstsystem! Platziere Bäume auf Deiner Fläche und erhalte sofort Infos 
                        über die Leistungen Deines geplanten Systems. Mit diesem Tool wird die Planung nachhaltiger
                        Landwirtschaft einfach und intuitiv.
                    </Typography>

                    <Box width="100%" mt={2} flexGrow={1} display="flex" flexDirection="row" justifyContent="space-between">
                        <Box width="300px" height="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <span />

                            <Box display="flex" flexDirection="column">
                            <Button size="large" variant="contained" color="primary" component={Link} to="/app">
                                Neu anlegen
                            </Button>
                            
                            <input 
                                id="project-upload"
                                type="file" 
                                //style={{clip: 'rect(0 0 0 0)', clipPath: 'inset(50%)', overflow: 'hidden', bottom: 0, left: 0, width: 1, height: 1, position: 'absolute'}} 
                                style={{display: 'none'}}
                                onChange={e => readProjectFromFile(e.target.files![0]).then(() => navigate('/app'))}
                            />
                            <label htmlFor="project-upload">
                                <Button startIcon={<CloudUpload />} size="large" variant="contained" color="primary" component="span" sx={{mt: 4}}>
                                    Hochladen
                                </Button>
                            </label>

                            </Box>

                            <Box display="flex" flexDirection="column">
                                <a href="https://www.iww.uni-freiburg.de/" target="_blank">
                                    <img src="/icons/ufr_logo.png" alt="UFR Logo" style={{maxWidth: '200px'}} />
                                </a>
                                <a href="https://www.uni-hohenheim.de" target="_blank">
                                    <img src="/icons/hohenheim_logo.png" alt="Hohenheim Logo" style={{maxWidth: '200px'}} />
                                </a>
                                <img src="/icons/BMEL_transp.png" alt="BMEL logo" style={{maxWidth: '200px'}} />
                            </Box>
                        </Box>
                        <Box height="100%" flexGrow={1} p={2} display="flex">
                            <TutorialSlideshow />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="end" alignItems="center">
                            <span style={{display: 'flex'}}>
                                <span style={{marginRight: '5px'}}>Umsetzung durch </span>
                                 <a href="https://hydrocode.de/" target="_blank">
                                    <img src="/icons/hydrocode_logo.png" alt="Hydrocode Logo" style={{maxWidth: '80px'}} />
                                </a>
                            </span>
                        </Box>
                </Box>
            </Box>

            <Footer noLandingPage />
        </Box>
    </>
}

export default DesktopLanding