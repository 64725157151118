import { Button, ButtonProps } from "@mui/material"
import { createProjectSnapshot } from "./project"
import { useState } from "react"
import { project } from "../../appState/projectSignals"
import { referenceArea } from "../../appState/referenceAreaSignals"



const DownloadProjectButton: React.FC<ButtonProps> = ({ ...btnProps }) => {
    // create a state for the blob
    const [isProcessing, setProcessing] = useState<boolean>(false)

    // the click handler
    const onClick = () => {
        setProcessing(true)
        const project = createProjectSnapshot()
        
        // for dev reasons
        console.log(project)

        // create a blob
        const blob = new Blob([JSON.stringify(project, null, 4)], { type: "application/json" })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `agroforst-planung-${new Date().toISOString().split('T')[0]}.json`
        link.click()
        link.remove()

        // open a new tab with this url
        //window.open(url, '_blank')?.focus()
        setProcessing(false)
    }
    return <>
        <Button {...btnProps} onClick={onClick} disabled={isProcessing || referenceArea.value.features.length === 0}>
            { isProcessing ? 'rechnet...' : 'Projekt speichern' }
        </Button>
    </>
}

export default DownloadProjectButton